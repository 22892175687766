import React from "react";
import { Link, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../../css/navbar.css";
import Login from "./../login/login";
import SearchResult from "./../search/display";
import $ from "jquery";
// import $ from "jquery";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
//import Logo from "https://push-1729.5centscdn.com/http/Logo-Big-Movie-Zoo_new.png";
// import User from "./../../assets/img/core-img/user.png";
import { auth } from "./../Firebase/firebase";
import { FcMenu } from "react-icons/fc";
// require("bootstrap/dist/css/bootstrap.min.css");
// require("jquery/dist/jquery.min");
require("bootstrap/dist/js/bootstrap.min");

const searchContentStyle = {
  width: "100%",
  //paddingTop: "50px",
  backgroundColor: "#111",
};

const loginContentStyle = {
  width: "80%",
  // paddingTop: "45px",
  backgroundColor: "#111",
};

const LoginModal = ({ toggleButton }) => (
  <Popup
    trigger={
      <a href="#" onClick={toggleButton}>
        {" "}
        Login{" "}
      </a>
    }
    modal
    contentStyle={loginContentStyle}
  >
    <Login />
  </Popup>
);

const LogoutModal = ({ toggleButton }) => (
  <Popup
    trigger={
      <a
        href="#"
        onClick={() => {
          toggleButton();
          auth.signOut();
          window.location.reload(false);
        }}
      >
        {" "}
        Logout{" "}
      </a>
    }
    modal
  >
    <Login />
  </Popup>
);

const SearchModal = ({ toggleButton }) => (
  <Popup
    style={{ zIndex: "2000" }}
    trigger={
      <a href="#" onClick={toggleButton}>
        {" "}
        Search{" "}
      </a>
    }
    modal
    contentStyle={searchContentStyle}
  >
    {(close) => {
      return (
        <div className="App" style={{ zIndex: "2000" }}>
          <SearchResult closeModel={close} />
        </div>
      );
    }}
  </Popup>
);

class Navbar extends React.Component {
  listener = null;
  state = {
    nav: false,
  };
  // constructor(props) {
  //   super(props);
  //   $(document).ready(function () {
  //     $(".popup-overlay").css({ zIndex: "2000" });
  //     console.log("nav", $(".popup-overlay").children());
  //   });
  // }

  refreshPage = () => {
    window.location.reload(false);
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    // $(".popup-overlay").css({ zIndex: "2000" });
    // console.log("nav length", $(".popup-overlay").length);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleMount = () => {
    //console.log("expanded", $(".navbar-toggler").attr("aria-expanded"));
    if ($(".navbar-toggler").attr("aria-expanded") === "false") {
      $("#nav").removeClass("ml-auto");
      $("#nav").addClass("mr-auto");
      this.setState({ nav: true });
      //console.log("exist");
    } else {
      $("#nav").removeClass("mr-auto");
      $("#nav").addClass("ml-auto");
      this.handleScroll();
      //console.log("not exist");
    }
  };

  handleToggleButton = () => {
    //console.log("expanded", $(".navbar-toggler").attr("aria-expanded"));
    if ($(".navbar-toggler").attr("aria-expanded") === "true") {
      $(".navbar-toggler").click();
      this.handleScroll();
    }
  };
  handleScroll = () => {
    if (window.pageYOffset > 140) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
        this.handleToggleButton();
      }
    }
  };

  /**
   * login logout code
   */
  // {this.props.currentUser === null ? (
  //   <LoginModal />
  // ) : (
  //   <Link
  //     to="/login"
  //     onClick={() => {
  //       auth.signOut();
  //     }}
  //   >
  //     Logout
  //   </Link>
  // )}

  render() {
    //console.log("nav1 currentUser: ", this.props.currentUser);
    return (
      <div>
        <nav
          className={`navbar navbar-expand-lg navbar-dark fixed-top ${
            this.state.nav && "Nav__black"
          }`}
        >
          <Link to="/" className="navbar-brand">
            <img
              className="Nav__logo"
              // src="https://push-1729.5centscdn.com/http/Logo-Big-Movie-Zoo_new.png"
              src="logo.png"
            />
          </Link>
          <button
            className="navbar-toggler"
            style={{ backgroundColor: "white" }}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.handleMount}
          >
            {/*<span className="navbar-toggler-icon"></span> */}
            <FcMenu />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul id="nav" className="navbar-nav ml-auto">
              <li className="nav-item li__nav">
                <Link to="/" onClick={this.handleToggleButton}>
                  Home
                </Link>
              </li>

              {/*<li className="nav-item li__nav">
                <Link to="/upcoming" onClick={this.handleToggleButton}>
                  Upcoming
                </Link>
              </li>*/}

              {/*<li className=" nav-item li__nav">
                <SearchModal
                  toggleButton={this.handleToggleButton}
                  onClick={this.handleToggleButton}
                />
            </li> */}

              <li className="nav-item li__nav">
                <Link to="/wallet" onClick={this.handleToggleButton}>
                  Subscribe
                </Link>
              </li>

              <li className="nav-item li__nav">
                {this.props.currentUser === null ||
                this.props.currentUser.name === "Guest" ? (
                  <LoginModal
                    toggleButton={this.handleToggleButton}
                    onClick={this.handleToggleButton}
                  />
                ) : (
                  <a
                    href="#"
                    onClick={() => {
                      var cnf = window.confirm("Do you wish to Logout??");
                      if (cnf) {
                        auth.signOut();
                        //return <Redirect to="/login" />;
                        //this.props.history.push("/login");
                      }
                      this.handleToggleButton();
                    }}
                  >
                    Logout
                  </a>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {};

export default Navbar;
