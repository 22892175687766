import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
//import Logo from "./../../assets/img/core-img/Logo-Big-Movie-Zoo_new.png";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import "./../../css/static.css";
import "./../../css/login.css";
import { auth, googleProvider, facebookProvider } from "./../Firebase/firebase";

const Login = ({ props }) => {
  const [agree, setAgree] = useState(true);

  useEffect(() => {
    //console.log("agree", agree);
  }, [agree]);

  function handleAgree(e) {
    // e.preventDefault();
    setAgree(!agree);
  }

  console.log("agree - ", agree);
  return (
    <Container className="login">
      <Row className="">
        <Col>
          <img
            className="login__logo"
            src="https://push-1729.5centscdn.com/http/Logo-Big-Movie-Zoo_new.png"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}> </Col>
        <Col xs={6}>
          <div className="login__separator">------ Social Login ------</div>
        </Col>
        <Col xs={3}></Col>
      </Row>
      <Row className="justify-content-center mt-1">
        <Col md={4} className="login__social">
          <GoogleLoginButton
            //style={{ fontSize: "2vw" }}
            className="login__social__buttons"
            onClick={() => {
              if (!agree) {
                alert("Please agree the Terms & Conditions before login!!");
              } else {
                auth.signInWithPopup(googleProvider).finally(() => {
                  //window.location.reload(false);
                  console.log("logged in");
                  return <Redirect to={"/"} />;
                });
              }
            }}
          />
        </Col>
        <Col md={2}>
          <div className="login__separator">OR</div>
        </Col>
        <Col md={4} className="login__social">
          <FacebookLoginButton
            //style={{ fontSize: "2vw" }}
            className="login__social__buttons"
            onClick={() => {
              if (!agree) {
                alert("PLease agree the Terms & Conditions before login!!");
              } else {
                //alert("Hello Facebo0k!!");
                auth.signInWithPopup(facebookProvider).finally(() => {
                  //window.location.reload(false);
                  props.history.push("/");
                  return <Redirect to={"/"} />;
                });
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      <Row className="align-items-center  mt-5">
        <Col md={2}></Col>
        <Col md={8} className="login__separator">
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Checkbox
                aria-label="Checkbox for following text input"
                checked={agree}
                onChange={handleAgree}
                onClick={handleAgree}
              />
              <span className="tnc-text">
                I agree with the Terms & Conditions Privacy Policy!!
              </span>
            </InputGroup.Prepend>
          </InputGroup>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
};

Login.propTypes = {};

export default withRouter(Login);
