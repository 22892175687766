import app from "firebase";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/analytics";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

app.initializeApp(config);

// class Firebase {
//   constructor() {
//     app.initializeApp(config);

//     /* Helper */

//     this.serverValue = app.database.ServerValue;
//     this.emailAuthProvider = app.auth.EmailAuthProvider;

//     /* Firebase APIs */

//     this.auth = app.auth();
//     this.db = app.database();
//     this.firestore = app.firestore();

//     /* Social Sign In Method Provider */

//     this.googleProvider = new app.auth.GoogleAuthProvider();
//     this.facebookProvider = new app.auth.FacebookAuthProvider();
//     this.twitterProvider = new app.auth.TwitterAuthProvider();
//   }

//   // *** Auth API ***

//   doCreateUserWithEmailAndPassword = (email, password) =>
//     this.auth.createUserWithEmailAndPassword(email, password);

//   doSignInWithEmailAndPassword = (email, password) =>
//     this.auth.signInWithEmailAndPassword(email, password);

//   doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

//   doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

//   doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

//   doSignOut = () => this.auth.signOut();

//   doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

//   doSendEmailVerification = () =>
//     this.auth.currentUser.sendEmailVerification({
//       url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
//     });

//   doPasswordUpdate = (password) =>
//     this.auth.currentUser.updatePassword(password);

//   // *** Merge Auth and DB User API *** //

//   onAuthUserListener = (next, fallback) =>
//     this.auth.onAuthStateChanged((authUser) => {
//       if (authUser) {
//         this.user(authUser.uid)
//           .once("value")
//           .then((snapshot) => {
//             const dbUser = snapshot.val();

//             // default empty roles
//             if (!dbUser.roles) {
//               dbUser.roles = {};
//             }

//             // merge auth and db user
//             authUser = {
//               uid: authUser.uid,
//               email: authUser.email,
//               emailVerified: authUser.emailVerified,
//               providerData: authUser.providerData,
//               ...dbUser,
//             };

//             next(authUser);
//           });
//       } else {
//         fallback();
//       }
//     });

//   // *** User API ***

//   user = (uid) => this.db.ref(`users/${uid}`);

//   users = () => this.db.ref("users");

//   // *** Message API ***

//   message = (uid) => this.db.ref(`messages/${uid}`);

//   messages = () => this.db.ref("messages");
// }
export const analytics = app.analytics();
export const firebase = app;
export const googleProvider = new app.auth.GoogleAuthProvider();
export const facebookProvider = new app.auth.FacebookAuthProvider();
export const auth = app.auth();
export const firestore = app.firestore();
//export const messaging = app.messaging();

// try {
//   if (app.messaging.isSupported()) {
//     const messaging = app.messaging();
//     messaging
//       .getToken({
//         vapidKey: process.env.REACT_APP_VAPI_KEY,
//       })
//       .then((currentToken) => {
//         if (currentToken) {
//           subscribeTokenToTopic(currentToken, "movies");
//         }
//       })
//       .catch((err) => {
//         console.log("Error to get token", err);
//       });

//     messaging.onMessage((payload) => {
//       console.log(payload.notification);
//     });

//     // Otherwise, we need to ask the user for permission
//     if (Notification.permission !== "granted") {
//       Notification.requestPermission();
//     }
//   } else {
//     console.log("firebase messaging not supported");
//   }
// } catch (err) {
//   console.log(err);
// }

// function subscribeTokenToTopic(token, topic) {
//   fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
//     method: "POST",
//     headers: new Headers({
//       Authorization: `key=${process.env.REACT_APP_SERVER_KEY}`,
//     }),
//   })
//     .then((response) => {
//       if (response.status < 200 || response.status >= 400) {
//         console.log(response.status, response);
//       }
//       console.log(`"${topic}" is subscribed`);
//     })
//     .catch((error) => {
//       console.error(error.result);
//     });
//   return true;
// }

//export const db = app.database();

export default app;
