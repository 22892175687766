import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../../css/footer.css";
//import PlayStoreImg from "https://push-1729.5centscdn.com/http/google-play.png";
//import Logo from "https://push-1729.5centscdn.com/http/Logo-Big-Movie-Zoo_new.png";

function Footer() {
  return (
    <footer className="footer-area">
      <div className="main-footer-area section-padding-80-0">
        <Container>
          <Row className="justify-content-between">
            <Col sm={6} md={4} xl={3}>
              <div className="single-footer-widget mb-80">
                <h4 className="widget-title">PAGES</h4>

                <div className="seperator"></div>

                <ul className="footer-ul">
                  <li>
                    <Link to="/contactus">Contact Us </Link>
                  </li>
                  <li>
                    <Link to="/aboutus">About Us </Link>
                  </li>
                  {/*<!--
                  <li>
                    <Link to="/prices">Pricing </Link>
                  </li>
  --> */}
                </ul>
              </div>
            </Col>
            <Col sm={6} md={4} xl={3}>
              <div className="single-footer-widget mb-80">
                <h4 className="widget-title">USEFUL LINKS</h4>

                <div className="seperator"></div>

                <ul className="footer-ul">
                  <li>
                    <Link to="/termsandcondition">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/refundpolicy">Refund Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={6} md={4} xl={3}>
              <div className="single-footer-widget mb-80">
                <h4 className="widget-title">SUPPORT</h4>

                <div className="seperator"></div>
                <div className="single-footer-widget mb-80">
                  <div className="footer-content">
                    <div className="single-contact-info d-flex">
                      <div className="icon">
                        <i className="icon_question_alt2"></i>
                      </div>
                      <div className="text">
                        <Link to="/faq">FAQ</Link>
                      </div>
                    </div>

                    <div className="single-contact-info d-flex">
                      <div className="icon">
                        <i className="icon_phone"></i>
                      </div>
                      <div className="text">
                        <p>+91 9076427520</p>
                      </div>
                    </div>

                    <div className="single-contact-info d-flex">
                      <div className="icon">
                        <i className="icon_mail_alt"></i>
                      </div>
                      <div className="text">
                        <p>
                          <a
                            href="mailto:support@bigmoviezoo.com"
                            target="_blank"
                          >
                            Support@bigmoviezoo.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} xl={3}>
              <div className="single-footer-widget mb-80">
                <h4 className="widget-title">DOWNLOAD APP</h4>

                <div className="seperator"></div>

                <Container>
                  <Row>
                    <img
                      // src="https://push-1729.5centscdn.com/http/Logo-Big-Movie-Zoo_new.png"
                      src="logo.png"
                      alt=""
                      className="footer__img"
                    />
                  </Row>
                  <Row>
                    <p style={{ fontSize: "12px" }}>
                      Big movie zoo offers Premium and exclusive web series and
                      movies in multiple languages. Big movie zoo is a zoo for
                      all your entertainment.
                    </p>
                  </Row>
                  <Row>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.videostreaming.bmz"
                      target="_blank"
                    >
                      <img
                        src="https://push-1729.5centscdn.com/http/google-play.png"
                        alt=""
                      />
                    </a>
                  </Row>
                  <Row>
                    <ul>
                      <li style={{ display: "inline", margin: "10px" }}>
                        <a
                          href="https://www.facebook.com/BigMovieZooapp/"
                          target="_blank"
                          className="facebook"
                        >
                          <i
                            className="fa fa-facebook"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                            }}
                          ></i>
                        </a>
                      </li>
                      <li style={{ display: "inline", margin: "10px" }}>
                        <a
                          href="https://www.instagram.com/bigmoviezooapp/"
                          target="_blank"
                          className="instagram"
                        >
                          <i
                            className="fa fa-instagram"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                            }}
                          ></i>
                        </a>
                      </li>
                      <li
                        style={{
                          display: "inline",
                          margin: "10px",
                        }}
                      >
                        <a
                          href="https://www.youtube.com/channel/UC4A2JMs_zRHWDnlTkPIxArQ"
                          target="_blank"
                          className="youtube"
                        >
                          <i
                            className="fa fa-youtube-play"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                            }}
                          ></i>
                        </a>
                      </li>
                      <li
                        style={{
                          display: "inline",
                          margin: "10px",
                        }}
                      >
                        <a
                          href="https://twitter.com/bigmoviezoo"
                          target="_blank"
                          className="youtube"
                        >
                          <i
                            className="fa fa-twitter"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                            }}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="copywrite-area">
        <Container>
          <Row>
            <Col>
              <div className="copywrite-text">
                <p>
                  Version: 1.0.4 - Copyright © {new Date().getFullYear()} All
                  Rights Reserved to BMZ WORLDWIDE (OPC) PVT.LTD.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
