import React, { useEffect, useState } from "react";
import "./wallet.css";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { firestore } from "../Firebase/firebase";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const Main = styled.div`
  height: 90vh;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 24px;
  padding-right: 24px;
  background: black;
  justify-content: space-between;
  overflow-y: scroll;
  color: white;
`;

function Transactions({ currentUser, close }) {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    console.log(currentUser);
    getUserTransactionFun();
    async function getUserTransactionFun() {
      await firestore
        .collection("users")
        .doc(currentUser.userID)
        .collection("transactions")
        .get()
        .then((snapshot) => {
          //   console.log(snapshot.data());
          const fetchedCinemas = [];
          snapshot.forEach((document) => {
            const fetchedCinema = {
              id: document.id,
              ...document.data(),
            };
            fetchedCinemas.push(fetchedCinema);
          });

          // fetchedCinemas.forEach((cnm) => {
          //   console.log(cnm.image);
          //   console.log(images.get(cnm.image));
          // });

          //sort map by index
          fetchedCinemas.sort(function (a, b) {
            return b.time.toMillis() - a.time.toMillis();
          });
          setTransactions(fetchedCinemas);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return () => {
      setTransactions([]);
    };
  }, []);

  if (transactions == []) {
    return (
      <div>
        <Main>
          <div className="row">
            <div className="col-10 text-center">Transactions Loading...</div>
            <div className="col-2">
              <button
                className="btn btn-primary button"
                style={{ width: "20px", paddingRight: "10px" }}
                onClick={() => {
                  //console.log('modal closed ');
                  close();
                }}
              >
                close
              </button>
            </div>
          </div>
        </Main>
      </div>
    );
  } else {
    // console.log(transactions);
    return (
      <div>
        <Main>
          <Row>
            <div className="col-10 text-center">
              Total Coins - {currentUser.coins} - {transactions.length}
            </div>
            <div className="col-2" style={{ paddingLeft: "10px" }}>
              <button
                className="btn btn-primary button"
                onClick={() => {
                  //console.log('modal closed ');
                  close();
                }}
              >
                x
              </button>
            </div>
          </Row>
          <div style={{ height: "1rem" }}></div>
          <Row>
            <Table key="1">
              <Thead className="bg-primary">
                <Tr>
                  <Th md={3}>Amount</Th>
                  <Th md={6}>Details</Th>
                  <Th md={3}>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactions.map((txn, index) => (
                  <Tr
                    className={`${
                      index % 2 === 0 ? "bg-info" : "bg-secondary"
                    }`}
                    key={index}
                  >
                    <Td md={3}>
                      {txn.currency}{" "}
                      <span className={` coin-text `}>{txn.amount} </span>
                    </Td>
                    <Td md={6}>
                      {txn.pg} - {txn.transactionId} - {txn.type}
                    </Td>
                    <Td md={3}>
                      <span
                        className={` ${
                          txn.status === "failed"
                            ? "failed-text"
                            : txn.status === "success"
                            ? "success-text"
                            : "text-light"
                        } `}
                      >
                        {txn.status}
                      </span>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Row>
        </Main>
      </div>
    );
  }
}

export default Transactions;
