import React, { useEffect, useState, useContext } from "react";
import { auth, firestore } from "./../Firebase/firebase";
// import { useStateIfMounted } from "use-state-if-mounted";

const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  // const [n, setN] = useStateIfMounted(0);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user !== null) {
        //console.log("userid: ", user.uid);
        await firestore
          .collection("users")
          .doc(user.uid)
          .get()
          .then(async (doc) => {
            //console.log("doc", doc.data());
            if (!doc.exists) {
              var initMap = {
                email: user.email,
                name: user.userName,
                photoUrl: user.photoURL,
                phoneNumber: user.phoneNumber,
                userID: user.uid,
                createdAt: Date.now(),
                isActive: false,
                productName: "",
                txn_date: Date.now(),
                token: "",
                tokenCreatedAt: Date.now(),
                tokenPlatform: "website",
                deviceID: "",
                deviceName: "",
                deviceModel: "",
              };

              //console.log("initMap", initMap);
              await firestore
                .collection("users")
                .doc(user.uid)
                .set(initMap)
                .catch((err) => console.log(err));
              setCurrentUser(initMap);
              setPending(false);
              // setN(1);
            } else {
              //console.log("doc", doc.data());
              setCurrentUser(doc.data());
              setPending(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //guest details
        //console.log("guest");
        var initMap = {
          email: "",
          name: "Guest",
          photoUrl: "",
          phoneNumber: "",
          userID: "12345678",
          createdAt: Date.now(),
          isActive: false,
          productName: "",
          txn_date: Date.now(),
          token: "",
          tokenCreatedAt: Date.now(),
          tokenPlatform: "website",
          deviceID: "",
          deviceName: "",
          deviceModel: "",
        };
        setCurrentUser(initMap);
        setPending(false);
      }
    });
  }, []);

  if (pending) {
    //console.log("pending", pending);
    return <></>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
