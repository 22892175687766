import React, { useReducer, useEffect } from "react";
import "./../../css/search.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import Movie from "./reasult";
import Search from "./search";
//import spinner from "./../../assets/img/core-img/ajax-loader.gif";
import { initialState, reducer } from "./reducer";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
//import PerfectScrollbar from "react-perfect-scrollbar";
import $ from "jquery";

//const MOVIE_API_URL = "https://www.omdbapi.com/?s=man&apikey=4a3b711b";
const MOVIE_API_URL = "https://bigmoviezoo.com/cdn/search";

function SearchDisplay({ closeModel }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // console.log(
    //   "navLength",
    //   console.log("navLength", $(".popup-overlay").length)
    // );
    $(".popup-overlay").css({ zIndex: "2000" });
    axios.get(MOVIE_API_URL).then((jsonResponse) => {
      //console.log("json", jsonResponse);
      let fetchedCinemas = jsonResponse.data.Search;
      fetchedCinemas.sort(function (a, b) {
        return a.Title.localeCompare(b.Title);
      });
      dispatch({
        type: "SEARCH_MOVIES_SUCCESS",
        payload: fetchedCinemas,
      });
    });
  }, []);

  // you can add this to the onClick listener of the Header component
  const refreshPage = () => {
    window.location.reload();
  };

  const search = (searchValue) => {
    dispatch({
      type: "SEARCH_MOVIES_REQUEST",
    });

    const fetchedCinemas = [];
    //console.log("Search", state);

    axios.get(MOVIE_API_URL).then((jsonResponse) => {
      //console.log("Search1", jsonResponse);
      jsonResponse.data.Search.forEach((obj) => {
        //console.log(obj.Title, obj.Title.toLowerCase().includes(searchValue));
        if (obj.Title.toLowerCase().includes(searchValue.toLowerCase())) {
          //console.log("include", obj.Title);
          fetchedCinemas.push(obj);
        }
      });

      fetchedCinemas.sort(function (a, b) {
        return a.Title.localeCompare(b.Title);
      });

      if (jsonResponse.data.Response === "True") {
        if (fetchedCinemas.length === 0) {
          fetchedCinemas.push(jsonResponse.data.Search[0]);
        }

        const searchJson = {
          Search: fetchedCinemas,
          totalResults: jsonResponse.data.totalResults,
          Response: jsonResponse.data.Response,
        };
        //console.log("searchJson", searchJson);
        dispatch({
          type: "SEARCH_MOVIES_SUCCESS",
          payload: fetchedCinemas,
        });
      } else {
        dispatch({
          type: "SEARCH_MOVIES_FAILURE",
          error: jsonResponse.data.Error,
        });
      }
    });
  };

  const { movies, errorMessage, loading } = state;

  const retrievedMovies =
    loading && !errorMessage ? (
      <img
        className="spinner"
        src="https://push-1729.5centscdn.com/http/ajax-loader.gif"
        alt="Loading spinner"
      />
    ) : errorMessage ? (
      <div className="errorMessage">{errorMessage}</div>
    ) : (
      movies.map((movie, index) => (
        <Col md={3} className="justify-content-xs-center">
          <Movie
            key={`${index}-${movie.Title}`}
            movie={movie}
            closeModel={closeModel}
          />
        </Col>
      ))
    );

  return (
    <div>
      <div className="content">
        <Search search={search} closeModel={closeModel} />

        <Container className="vertical-scrollable search-scroll-class">
          <Row className="justify-content-md-center">{retrievedMovies}</Row>
        </Container>
      </div>
    </div>
  );
}

export default SearchDisplay;
