import React, { useState, useEffect, Suspense, lazy } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import ScrollToTop from "./component/common/ScrollToTop";
import "./App.css"; // requires a loader
import Nav from "./component/navbar/navbar";
//import Home from "./component/home/home";
//import VideoDetails from "./component/video/videodetails";
// import Login from "./component/login/login";
import Footer from "./component/footer/footer";
import PrivateRoute from "./component/auth/privateRoute";
import Login from "./component/login/login";
//import TermOfUse from "./component/static/termsOfUse";
//import RefundPolicy from "./component/static/refundPolicy";
//import PrivacyPolicy from "./component/static/privacyPolicy";
//import Pricing from "./component/static/pricing";
//import ContactUs from "./component/static/contactUs";
//import AboutUs from "./component/static/aboutUs";
//import FAQ from "./component/static/faq";
//import MultiPayment from "./component/payment/multiPayment";
//import PayuPaymentStatus from "./component/payment/payuPaymentStatus";
//import Upcoming from "./component/upcoming/upcoming";
//import VideoPlayer from "./component/video/videoPlayer";
import { useAuth } from "./component/auth/auth";
import Transactions from "./component/wallet/transactions";
//import PaymentFailed from "./component/payment/paymentFailed";
//import PaymentSuccess from "./component/payment/paymentSuccess";
//import { firebase } from "./component/Firebase/firebase";

// const Footer = lazy(() =>
//   import(/* webpackChunkName: 'footer'*/ "./component/footer/footer")
// );

const Home = lazy(() =>
  import(/* webpackChunkName: 'home'*/ "./component/home/home")
);

const TermOfUse = lazy(() =>
  import(/* webpackChunkName: 'termsOfUse'*/ "./component/static/termsOfUse")
);

const Upcoming = lazy(() =>
  import(/* webpackChunkName: 'upcoming'*/ "./component/upcoming/upcoming")
);
const FAQ = lazy(() =>
  import(/* webpackChunkName: 'faq'*/ "./component/static/faq")
);
const AboutUs = lazy(() =>
  import(/* webpackChunkName: 'aboutUs'*/ "./component/static/aboutUs")
);
const ContactUs = lazy(() =>
  import(/* webpackChunkName: 'contactUs'*/ "./component/static/contactUs")
);
const Pricing = lazy(() =>
  import(/* webpackChunkName: 'pricing'*/ "./component/static/pricing")
);
const PrivacyPolicy = lazy(() =>
  import(
    /* webpackChunkName: 'privacyPolicy'*/ "./component/static/privacyPolicy"
  )
);
const RefundPolicy = lazy(() =>
  import(
    /* webpackChunkName: 'refundPolicy'*/ "./component/static/refundPolicy"
  )
);

const Wallet = lazy(() =>
  import(/* webpackChunkName: 'BMZ wallet'*/ "./component/wallet")
);

const VideoDetails = lazy(() =>
  import(/* webpackChunkName: 'videodetails'*/ "./component/video/videodetails")
);
const MultiPayment = lazy(() =>
  import(/* webpackChunkName: 'multipay'*/ "./component/payment/multiPayment")
);
const PaymentFailed = lazy(() =>
  import(/* webpackChunkName: 'payfailed'*/ "./component/payment/paymentFailed")
);
const PaymentSuccess = lazy(() =>
  import(
    /* webpackChunkName: 'paysuccess'*/ "./component/payment/paymentSuccess"
  )
);
const VideoPlayer = lazy(() =>
  import(/* webpackChunkName: 'videoplayer'*/ "./component/video/videoPlayer")
);
const DownloadAppPage = lazy(() =>
  import(/* webpackChunkName: 'downloadApp'*/ "./component/common/download_app")
);

function App() {
  const [images, setImages] = useState([]);
  const { currentUser } = useAuth();
  //const [token, setToken] = useState("");

  useEffect(async () => {
    // const msg = firebase.messaging();
    // msg
    //   .requestPermission()
    //   .then(() => {
    //     return msg.getToken();
    //   })
    //   .then((token) => {
    //     console.log(token);
    //     setToken(token);
    //   });

    await axios
      .get("https://bigmoviezoo.com/cdn/count")
      .then((res) => {
        //console.log("Response from main API: ", res);
        var eMap = new Map();
        Object.keys(res.data.images).map((key, i) =>
          //console.log(res.data.images[key])
          eMap.set(res.data.images[key].name, res.data.images[key].thumbnail)
        );
        //emap = res.data.images;
        // res.data.images.map((image) => {
        //   console.log(image.name);
        // });
        setImages(eMap);
        //console.log("Home Data: ", emap);
        //console.log("Colors Data: ", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Main = withRouter(({ location }) => {
    return (
      <Route>
        <ScrollToTop>
          <div className="app">
            <Suspense fallback={<div />}>
              {location.pathname !== "/multipayment" &&
                location.pathname !== "/paymentSuccess" &&
                location.pathname !== "/login" &&
                location.pathname !== "/paymentFailed" && (
                  <Nav currentUser={currentUser} />
                )}

              <Switch>
                <PrivateRoute
                  exact
                  path="/"
                  component={() => (
                    <Home images={images} currentUser={currentUser} />
                  )}
                />

                <Route
                  exact
                  path="/login"
                  component={(props) => <Login props={props} />}
                ></Route>

                {/* <Route
                  path="/details/:videoRef"
                  exact
                  render={(props) => (
                    <VideoDetails
                      props={props}
                      images={images}
                      currentUser={currentUser}
                      key={Date.now()}
                    />
                  )}
                ></Route>

                <Route
                  path="/upcoming"
                  exact
                  render={() => <Upcoming images={images} />}
                />

                <Route
                  path="/player"
                  exact
                  // component={(props) => (
                  //   <VideoPlayer
                  //     props={props}
                  //     src={
                  //       "https://97enqyzqog4j-hls-push.5centscdn.com/mp4/raw/Killer%20Hindi%20EP%201_New_combined.smil/playlist.m3u8"
                  //     }
                  //     controls={true}
                  //     autoplay={false}
                  //   />
                  // )}
                  component={() => (
                    <VideoPlayer src="https://97enqyzqog4j-hls-push.5centscdn.com/mp4/raw/Killer%20Hindi%20EP%201_New_combined.smil/playlist.m3u8" />
                  )}
                  />*/}

                <Route path="/termsandcondition" exact component={TermOfUse} />
                <Route path="/refundpolicy" exact component={RefundPolicy} />
                <Route path="/privacypolicy" exact component={PrivacyPolicy} />
                <Route path="/contactus" exact component={ContactUs} />
                <Route path="/aboutus" exact component={AboutUs} />
                <Route
                  path="/prices"
                  exact
                  component={() => <Pricing currentUser={currentUser} />}
                />
                <Route
                  path="/wallet"
                  exact
                  component={() => <Wallet currentUser={currentUser} />}
                />
                <Route
                  path="/transaction"
                  exact
                  component={() => <Transactions currentUser={currentUser} />}
                />
                <Route path="/faq" exact component={FAQ} />
                <Route
                  path="/download_app "
                  exact
                  component={DownloadAppPage}
                />
              </Switch>

              {location.pathname !== "/multipayment" &&
                location.pathname !== "/download_app" &&
                location.pathname !== "/paymentSuccess" &&
                location.pathname !== "/login" &&
                location.pathname !== "/paymentFailed" && <Footer />}
            </Suspense>
          </div>
        </ScrollToTop>
      </Route>
    );
  });

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/paymentSuccess" exact component={PaymentSuccess} />

          <Route path="/paymentFailed" exact component={PaymentFailed} />

          <Route
            //http://localhost:3000/multipayment?app=web&userId=8EM2OEhSNpa58xHLakVLmUfVJjM2&product=PRO&amount=149&currency=INR&customerName=vivek&customerEmail=vivekn4u@gmail.com&customerMobile=9930546346
            //path="/multipayment/:userId/:customerName/:customerEmail/:customerMobile/:product/:amount/:currency,"
            path="/multipayment"
            exact
            component={(props) => <MultiPayment props={props} />}
          />
        </Switch>
      </Suspense>
      <Main />
    </Router>
  );
}

export default App;
